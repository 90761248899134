import Layout from "../components/layout";
import React from "react";

const Page404 = () => {
  return (
    <>
      <Layout>
        <h1>Page404</h1>
      </Layout>
    </>
  );
};

export default Page404;
